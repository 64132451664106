import store from '../store/main'
import router from '../router'

export default {
    async refreshAccessToken(): Promise<void | unknown> {
        const refreshTokenRequest = { refreshToken: ''};
        const userToken = store.state.userToken as unknown as { refreshToken?: string };

        //let res: any;
        if (userToken && userToken.refreshToken) {
            refreshTokenRequest.refreshToken = userToken.refreshToken;

            //var path = router.currentRoute.value;
            const res = await store.dispatch('refreshToken', refreshTokenRequest).then((res) => {
                return res;
            })
            .catch(() => {
                window.sessionStorage.clear();
                router.push({ name: 'NonIssueView' });
                return null;
            });

            return res;
        } else {
            window.sessionStorage.clear();
            router.push({ name: 'NonIssueView' });
            return null;
        }
    }

}
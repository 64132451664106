import '@/scss/setup.scss'
import * as bulmaToast from 'bulma-toast'
import 'lazysizes';

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import AuthService from '@/services/auth-service'
import Axios, { InternalAxiosRequestConfig, AxiosError } from 'axios'
import mainStore from './store/main'
import i18n from './i18n'

Axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;
// Request interceptor
Axios.interceptors.request.use(
    (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
        const user = mainStore.state.userToken as unknown as { accessToken?: string };
        
        const token = user !== null && user.accessToken !== undefined ? user.accessToken : '';
    
        // Set the Authorization header with the token
        config.headers['Authorization'] = `Bearer ${token}`;
    
        return config;
      },
      (error: AxiosError) => {
        return Promise.reject(error);
      }
);
// Response interceptor for API calls
Axios.interceptors.response.use((response) => {
    return response
}, async function (error) {
    const originalRequest = error.config;
    
    if (error.response) {
        // Request made and server responded
        const isAuthorisationError = error.response.status === 403 || error.response.status === 401;

        if (isAuthorisationError && !originalRequest._retry) {
            originalRequest._retry = true;

            const res = await AuthService.refreshAccessToken();
            if(res !== null){
                const userToken = mainStore.state.userToken as unknown as { accessToken?: string };
                const token = userToken !== null && userToken.accessToken !== undefined ? userToken.accessToken : "";
                Axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    
                if (originalRequest.data && typeof originalRequest.data === 'string')
                    originalRequest.data = JSON.parse(originalRequest.data); //otherwise the content type is set as text not json on second attempt
    
                return Axios(originalRequest);
            }

        }

    } else if (error.request) {
        // The request was made but no response was received
    } else {
        // Something happened in setting up the request that triggered an Error
    }

    return Promise.reject(error);
}
);

bulmaToast.setDefaults({
    duration: 3500,
    position: 'top-center'
})


const app = createApp(App);
app.use(mainStore)
app.use(router)
app.use(i18n)


mainStore.dispatch('getSiteSettings')
    .then(() => {
        return mainStore.dispatch('getUser').then(() => {
            return mainStore.dispatch("buildMenu", mainStore.state.siteSettings);
        })
        .catch(error => {
            console.error('Error during initialization:', error);
        })
            
    })
    .catch(error => {
        console.error('Error during initialization:', error);
    })
    .finally(() => {
        app.mount('#app');
    });


app.config.performance = true;
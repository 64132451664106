import { toRef } from '@vue/reactivity'
import { createStore } from 'vuex'
import Axios from 'axios'
import createPersistedState from 'vuex-persistedstate'
import {
    GeolocationData, PricingTiers, SiteSettings,
    PromoCodes, cart_item,
    Result, ArchiveSitesTitles, GroupedIssues,
    UserLogin, UserLoginToken
} from '@/interfaces/interface';

import AuthService from '@/services/auth-service'

const MainStore = createStore({
  plugins: [
    createPersistedState({
        paths: [
            'selectedTitleId', 
            'selectedTitleSlug',
            'pricingTiers',
            'geolocation',
            'basket',
            'language',
            'pageLoad',
            'isLoginModalActive',
            'isDownloadAppModalActive',
            'newUser',
            'userToken'

            //'siteSettings','issues','sideMenu','user',
        ]
    }),
  ],
  state: {
    newUser: {},
    user: {} as UserLogin,
    userToken: {} as UserLoginToken,
    siteSettings: {} as SiteSettings,
    sideMenu: [],
    selectedTitleId: 0,
    selectedTitleSlug: '',
    issues: [] as GroupedIssues[],

     /*Generic settings*/
    pricingTiers: [] as PricingTiers[],
    geolocation: {} as GeolocationData,
    language: { code: 'en', img: '' },
    pageLoad: false,
    isLoginModalActive: false,
    isDownloadAppModalActive: false,

    /*Basket*/
    basket: {} as cart_item,
    validPromoCode: {} as PromoCodes,

    endpointPromise: {} as Record<string, unknown> //store the promise here, and check it to provent multiple loads
  },
  mutations: {
    SET_NEWUSER(state, payload) {
      state.newUser = payload;
    },
    SET_USER(state, payload: UserLogin) {
        state.user = payload;

        state.userToken = {
            refreshToken: payload.refreshToken,
            accessToken: payload.accessToken,
            tokenExpiration: payload.tokenExpiration,
            userId: payload.userId
        } as UserLoginToken
    },
    SET_USER_TOKENS(state, payload: UserLoginToken) {
        state.userToken = payload;
    },
    SET_USER_SUBSCRIPTIONS(state, payload){
      state.user.userTitleSubscription = payload.userTitleSubscription
      state.user.issuesOwned = payload.issuesOwned
    },
    SET_SITE_SETTINGS(state, payload) {
        if (payload.archiveSitesTitles !== undefined) {
            state.siteSettings = payload

            //set first title in the list as the default title
            state.selectedTitleId = payload.archiveSitesTitles[0].titleId
            state.selectedTitleSlug = payload.archiveSitesTitles[0].titleSlug
        }
    },
    SET_SIDE_MENU(state, payload){
      state.sideMenu = payload
    },
    SET_TITLEISSUES(state, payload){
      state.issues = payload
    },

    SET_PRICINGTIERS(state, payload) {
      state.pricingTiers = payload;
    },
    SET_GEOLOCATIONDATA(state, payload){
      state.geolocation = payload
    },

    addToBasket(state, item: cart_item) {
      state.basket = item
    },
    removeFromBasket(state) {
      state.basket = {} as cart_item
    },
    SET_VALID_PROMOCODE(state, payload){
      state.validPromoCode = payload
    },

    SET_LANGUAGE(state, payload){
      state.language = payload
    },

    SET_PAGELOAD(state, payload){
      state.pageLoad = payload
    },
    SET_LOGINMODAL(state, payload){
      state.isLoginModalActive = payload
    },
    SET_DOWNLOADAPPMODAL(state, payload){
      state.isDownloadAppModalActive = payload
    },
    STORE_PROMISE(state, payload) {
      state.endpointPromise[payload.key] = toRef(state, payload.data);
    },
  },
  actions: {
    async authenicate({ commit }, payload) {

      if (payload.options === undefined)
          payload.options = null;

      if (payload.form === undefined)
          payload.form = null;

      const res = await Axios.post(payload.url, payload.form, payload.options).then(function (response) {
          commit('SET_USER', response.data) 
          return null;
      }).catch((error) => {
          return error.response.data
      });

      return res;
    },
    async refreshToken({ state, commit }, key) {

        const promiseKey = "RefreshTokenUser" + key.refreshToken;

        //return existing promise, prevents multiple api calls
        if (state.endpointPromise[promiseKey] !== null && state.endpointPromise[promiseKey] !== undefined)
            return state.endpointPromise[promiseKey];

        //need the title ids to get refresh token
        key.titleIds = state.siteSettings.archiveSitesTitles.map((obj:ArchiveSitesTitles) => obj.titleId);

        //call endpoint
        const promise = Axios.post('Authenticate/refresh-token', key).then((response) => {
                commit('SET_USER', response.data); //set data
                commit('STORE_PROMISE', { key: promiseKey, data: null }); //clear promise
                return response.data.accessToken;
            });

        commit('STORE_PROMISE', { key: promiseKey, data: promise });
        return promise;
    },

    async logout({ state, commit }){
      await Axios.post('Authenticate/logout?refreshToken=' + state.user.refreshToken).then(() => {
          commit('SET_USER', {} as UserLogin)
          commit('SET_USER_TOKENS', {} as UserLoginToken)
          /*commit('SET_SIDE_MENU', [])*/
          //commit('SET_GEOLOCATIONDATA', {} as GeolocationData)   
          window.sessionStorage.clear()   
      })
    },
    async forgotPassword({ commit }, key){

      //call endpoint
      const promise = Axios.get(`Authenticate/forgot-password`, { params: { email: key } }).then((response) => {
        return response.data;
      });

      commit('STORE_PROMISE', { key: 'forgotPassword', data: promise });
      return promise;
      },


      async getSiteSettings({ commit, state }) {

          const promiseKey = "GetSiteSettings";
          //return existing promise, prevents multiple api calls
          if (state.endpointPromise[promiseKey] !== null && state.endpointPromise[promiseKey] !== undefined)
              return state.endpointPromise[promiseKey];

          //call endpoint
          const promise = Axios.get('Settings/site-settings').then((response) => {
              commit('SET_SITE_SETTINGS', response.data)

              return response.data
          });

          commit('STORE_PROMISE', { key: promiseKey, data: promise });
          return promise;

      },
    async getUser({ commit, state }) {
          //if missing user but has token!
          const hasUser = Object.keys(state.user).length > 0;
          const hasUserToken = Object.keys(state.userToken).length > 0;

          if (hasUser)
              return state.user;
          else if (hasUserToken) { //reload user info from token/ call refresh?
              await AuthService.refreshAccessToken();

              return state.user;
              
          } else {
              return null; //not logged in at all!
          }
      },
    async buildMenu({ commit, state }, settings){
      if(settings.archiveSitesTitles !== undefined){
        if(settings.archiveSitesTitles.length > 0){
          const form = {
            userId: state.user !== null ? state.user.userId : null,
            isMultiTitle: settings.isMultiTitle,
            forceRefresh: true
          }
          await Axios.get('Title/site-nav', { params: form }).then((response) => {
            commit('SET_SIDE_MENU', response.data)
            const lng = { code: 'en', img: '../../assets/en.webp' }
            commit('SET_LANGUAGE', lng)
          })
        }
      }

    },


    async getAllTitleIssues({ state, commit }){
      const titleId = state.siteSettings.archiveSitesTitles?.length === 1 ? state.siteSettings.archiveSitesTitles[0].titleId : null

      const response = await Axios.get(`Title/title/${titleId}/all-issues-for-title`)
      commit('SET_TITLEISSUES', response.data);
      
      return state.issues;
    },

    async getTitleIssuesById({state},issueId){
      const titleId = state.siteSettings.archiveSitesTitles?.length === 1 ? state.siteSettings.archiveSitesTitles[0].titleId : null

      const response = await Axios.get(`Title/title/${titleId}/issue/${issueId}/issue-base`)      
      return response.data;
    },

    loadPricingTiers({ state, commit }) {
      const lengthOfPricingTiers: number = state.pricingTiers.length

      if(lengthOfPricingTiers === 0){
        Axios.get('Generic/pricing-tiers').then(function (response) {
          commit('SET_PRICINGTIERS', response.data);
        })
      }
    },

    addToBasket({ commit }, item: cart_item) {
      commit('addToBasket', item);
    },
    removeFromBasket({ commit }) {
      commit('removeFromBasket');
    },
    async checkPromoCode({ state, commit }, payload){
      const response = await Axios.get('Checkout/check-valid-promocode', { params: payload });
      commit('SET_VALID_PROMOCODE', response.data);
      if(state.validPromoCode.result.status){
        const discountAmount: number = (state.basket.price * response.data.percentOff) / 100;
        const discountedPrice: number = state.basket.price - discountAmount;
        state.basket.price = parseFloat(discountedPrice.toFixed(2))
      }else{
        state.basket.price = state.basket.orginalPrice
      }
    },

    getLocationData({ commit }){
      Axios.get<GeolocationData>('Generic/user-location-data').then((response) => {
        commit('SET_GEOLOCATIONDATA', response.data)
      })
    },

    pageLoading({ commit }, payload){
      commit('SET_PAGELOAD', payload);
    },
    loginModalActive({ commit }, payload){
      commit('SET_LOGINMODAL', payload);
    },
    downloadModalActive({ commit }, payload){
      commit('SET_DOWNLOADAPPMODAL', payload);
    },
  },
  modules: {
  },
  getters: {
    basketItems(state) {
      if(state.basket)
        return state.basket;
    },
    isPromoCodeValid(state){
      if(state.validPromoCode.result === undefined){
        const myval: Result = {
          status: false,
          message: ''
        }
        state.validPromoCode.result = myval
      }
      return state.validPromoCode
    }
  },
  
})

export default MainStore;

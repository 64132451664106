import { createRouter, createWebHistory } from 'vue-router'
import store from '../store/main'

const routes = [
  {
    meta: {
        title: 'Default',
        public: true,
    },
    path: '/',
    name: 'MainView',
    component: () => import(/* webpackChunkName: "main" */ '../views/MainView.vue'),
    children: [
      {
        meta: {
            title: 'Login',
            public: true       
        },
        path: '/login',
        name: 'NonIssueView',
        component: () => import(/* webpackChunkName: "main" */ '../views/NonIssueView.vue'),
      },
      {
        meta: {
            title: 'Issues'
        },
        path: `/:titleSlug/issues`,
        name: 'IssueView',
        component: () => import(/* webpackChunkName: "home" */ '../views/IssueView.vue')
      },
      {
        meta: {
            title: 'My Account',
            public: false 
        },
        path: '/my-account',
        name: 'MyAccount',
        component: () => import(/* webpackChunkName: "account" */ '../views/MyAccount.vue'),
        props: true
      },
      {
        meta: {
            title: 'Members Support',
            public: false 
        },
        path: '/members/support',
        name: 'SupportView',
        component: () => import(/* webpackChunkName: "support" */ '../views/SupportView.vue'),
        props: true
      },
      {
        meta: {
            title: 'Register',
            public: true
        },
        path: '/members/register/:step',
        name: 'RegistrationView',
        component: () => import(/* webpackChunkName: "activation" */ '../views/RegistrationView.vue'),
        props: true
      },
      {
        meta: {
            title: 'Payment',
            public: true
        },
        path: '/member/payment/:step',
        name: 'PaymentView',
        component: () => import(/* webpackChunkName: "funnel" */ '../views/PaymentView.vue'),
        props: true
      },
      {
        meta: {
            title: 'Search',
            public: true
        },
        path: '/search',
        name: 'SearchResults',
        component: () => import(/* webpackChunkName: "search" */ '../views/SearchResults.vue'),
        props: true
      },
    ]
  },

  {
    meta: {
      title: 'Forgotten Password',
      public: true       
    },
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import(/* webpackChunkName: "password" */ '../views/ForgotPassword.vue'),
  },
  {
    meta: {
      title: 'Change Password',
      public: true       
    },
    path: '/password-reset',
    name: 'ChangePassword',
    props: true,
    component: () => import(/* webpackChunkName: "password" */ '../views/ChangePassword.vue'),
  },

  {
    meta: {
      title: 'Payment',
      public: true       
    },
    path: '/payment',
    name: 'PaymentFlow',
    component: () => import(/* webpackChunkName: "funnel" */ '../components/templates/PaymentFlow.vue'),
  },
  {
    meta: {
      title: 'Register',
      public: true       
    },
    path: '/register/:step',
    name: 'RegistrationFlow',
    component: () => import(/* webpackChunkName: "activation" */ '../components/templates/RegistrationFlow.vue'),
  },
  // 404 Not Found route
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "notfound" */ '../views/NotFound.vue'),
  },

]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
        return {
            el: to.hash,
            behavior: 'smooth'
        }
    }
    return { top: 0 }
  }
})


router.beforeEach((to, from, next) => {
    // Set initial states and get necessary information
    store.dispatch('pageLoading', true);
    store.dispatch('loginModalActive', false);
    store.dispatch('downloadModalActive', false);
    const pageTo = to.name;
    const ifUserLoggedIn = store.state.userToken && store.state.userToken.userId && Object.hasOwnProperty.call(store.state.userToken, 'accessToken');

    // If navigating to 'NotFound', stop loading and proceed
    if (pageTo === 'NotFound') {
        store.dispatch('pageLoading', false);
        return next();
    }

    // Handle site settings and menu building 
    //if this dispatch is called in app start up then store.state.siteSettings can be just used here
    //or just leave it knowing it will get from cache

    // Redirect logic based on user login status and page meta
    if (ifUserLoggedIn) {
        if (pageTo === 'MainView' || pageTo === 'NonIssueView') {
            return next({ name: 'IssueView', params: { titleSlug: store.state.selectedTitleSlug } });
        }
        if (to.meta.public === false) {
            return next();
        }
    }
    else if (!ifUserLoggedIn && pageTo !== 'NonIssueView') {
        const issueViewIsPublic = store.state.siteSettings.isIssueViewPublic;
        if (pageTo === "IssueView" && issueViewIsPublic) {
            return next();
        } else if (pageTo === "IssueView" && !issueViewIsPublic) {
            return next('/login');
        } else {
            if (pageTo === 'MainView') {
                return next('/login');
            } else {
                return next();
            }
        }
    }
    else {
        const issueViewIsPublic = store.state.siteSettings.isIssueViewPublic;
        if (pageTo === "IssueView" && issueViewIsPublic) {
            return next();
        }
        if (pageTo !== 'NonIssueView' && to.meta.public !== true) {
            return next('/login');
        }
    }

    // Default action if none of the above conditions are met
    store.dispatch('pageLoading', false);
    next();
});



const siteName = store.state.siteSettings.siteName
router.afterEach(to => {
    if (to.meta.title) {

        document.title = `${siteName === undefined ? 'Archive' : siteName} Web Library | ${to.meta.title}`
    } else {
        document.title = siteName
    }
})

export default router
